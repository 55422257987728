var cookies = require('../components/cookies');

var shippingZipCodeInput = $('.shippingZipCode , .billingZipCode , .addressBookZipCode');
var shippingAddressCityInput = $('.shippingAddressCity, .billingAddressCity , .addressBookCity');
var shippingStateInput = $('.shippingState , .billingState , .addressBookState'); // eslint-disable-line no-unused-vars
var shippingAddressOne = $('.shippingAddressOne, .billingAddressOne, .addressBookAddressOne');
var shippingAddressTwo = $('.shippingAddressTwo , .billingAddressTwo');

$(document).ready(function () {
    shippingZipCodeInput.attr('autocomplete', 'off');
    shippingAddressOne.attr('autocomplete', 'off');
    shippingAddressTwo.attr('autocomplete', 'off');
    shippingAddressCityInput.attr('autocomplete', 'off');

    var validationMessage = $('.addressone_invalid').val();
    var addressOneValue = $('.addressBookAddressOne').val();

    if (!!addressOneValue && $('.myaddress_form').length == -1) {
        shippingAddressOne.addClass('is-invalid').attr('aria-invalid', 'false');
        shippingAddressOne.closest('.form-group').find('.invalid-feedback').addClass('d-block').text(validationMessage);
        shippingAddressOne.closest('.form-group').find('.icon-CHECK').addClass('d-none');
        var checkoutStage = $('.data-checkout-stage').data('checkout-stage');
        if (checkoutStage === "billing") {
            $('button.submit-billing').attr('disabled', true).addClass("disabled-address-one");
            $('#js-billing-step-btn .submit-billing').attr('disabled', true).addClass("disabled-address-one");
        } else {
            $('button.submit-shipping').attr('disabled', true).addClass("disabled-address-one");
        }
    }
});

shippingZipCodeInput.attr('autocomplete', 'nope');

shippingAddressOne.on('focusout', function () {
    var $this = $(this);
    var addressOneValue = $this.val();
    var validationMessage = $('.addressone_invalid').val();
    var checkoutStage = $('.data-checkout-stage').data('checkout-stage');

    $('button.submit-shipping').removeClass("disabled-address-one");
    $('#js-billing-step-btn .submit-billing').removeClass("disabled-address-one");

    var address1 = new RegExp($this.data('pattern'));
    
    if (!$this.val()) {
        validationMessage = $this.data('missing-error');
    } else if (!address1.test(addressOneValue)) {
        validationMessage = $this.data('pattern-mismatch');
    }
    
    if (!$this.val() || !address1.test(addressOneValue)) {
        $this.removeClass('is-valid').addClass('is-invalid');
        $this.closest('.form-group').find('.invalid-feedback').addClass('d-block').text(validationMessage);
        $this.closest('.form-group').find('.icon-CHECK').addClass('d-none');
        if ($('.myaddress_form').length == -1) {
            if (checkoutStage === "billing") {
                $('button.submit-billing').attr('disabled', true).addClass("disabled-address-one");
            } else {
                $('button.submit-shipping').attr('disabled', true).addClass("disabled-address-one");
            }
        }
    } else {
        $this.removeClass('is-invalid').addClass('is-valid');
        $this.closest('.form-group').find('.invalid-feedback').removeClass('d-block').text("");
        $this.closest('.form-group').find('.icon-CHECK').removeClass('d-none');
    }
});

function formatLanguageAndCCode() {
    var countryData = cookies.getCookie('country_data').split('~');
    const countryCode = countryData[0];

    var data = {
        countryCode: countryCode,
        languageCode: countryData[1]
    };

    if (countryCode.toString() === 'IM' || countryCode.toString() === 'FR'){
        data.countryCode = $('.shippingCountry , .billingCountry , .addressBookCountry, .registerCountry').val() || '';
    }
    return data;
}

function initAddressField(step) {
    var shippingCountry;
    switch(step) {
        case 'addressBook':
            shippingZipCodeInput = $('.addressBookZipCode');
            shippingCountry = $('.addressBookCountry');
            shippingAddressCityInput = $('.addressBookCity');
            shippingStateInput = $('.shippingState');
            shippingAddressOne = $('.addressBookAddressOne');
            break;
        case 'shipping':
            shippingZipCodeInput = $('.shippingZipCode');
            shippingCountry = $('.shippingCountry');
            shippingAddressCityInput = $('.shippingAddressCity');
            shippingStateInput = $('.shippingState');
            shippingAddressOne = $('.shippingAddressOne');
            shippingAddressTwo = $('.shippingAddressTwo');
            break;
        case 'payment':
            shippingZipCodeInput = $('.billingZipCode');
            shippingCountry = $('.billingCountry');
            shippingAddressCityInput = $('.billingAddressCity');
            shippingStateInput = $('.billingState');
            shippingAddressOne = $('.billingAddressOne');
            shippingAddressTwo = $('.billingAddressTwo');
            break;
        default:
            shippingZipCodeInput = $('.shippingZipCode , .billingZipCode , .addressBookZipCode');
            shippingCountry = $('.shippingCountry , .billingCountry , .addressBookCountry'); // eslint-disable-line no-unused-vars
            shippingAddressCityInput = $('.shippingAddressCity, .billingAddressCity , .addressBookCity');
            shippingStateInput = $('.shippingState , .billingState , .addressBookState');
            shippingAddressOne = $('.shippingAddressOne , .billingAddressOne , .addressBookAddressOne');
            shippingAddressTwo = $('.shippingAddressTwo , .billingAddressTwo');
            break;
    }
}

module.exports = {
    formatLanguageAndCCode: formatLanguageAndCCode,
    initAddressField: initAddressField
};